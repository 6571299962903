
import { computed, defineComponent, PropType } from 'vue';
import RecommendationCard from '@/components/recommendation/RecommendationCardComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Icon from '@/components/icon/Icon';
import { Recommendation } from '@/models';
import Routes from '@/router/Routes';

export default defineComponent({
  components: {
    ButtonComponent,
    RecommendationCard
  },
  emits: ['submit', 'discard', 'remove', 'schedule'],
  props: {
    recommendations: {
      type: Array as PropType<Array<Recommendation>>
    },
    selectedRecommendations: {
      type: Array as PropType<Array<Recommendation>>,
      required: true
    },
    hiddenRecommendations: {
      type: Array as PropType<Array<Recommendation>>,
      required: true
    }
  },
  setup(props, { emit }) {
    const recommendationItems = computed(() => {
      return props.recommendations
        ?.map((recommendation) => {
          return {
            recommendation,
            active: isActiveRecommendation(recommendation)
          };
        })
        .filter((recommendationItem) => {
          return !isHiddenRecommendation(recommendationItem.recommendation);
        });
    });

    function handleSubmit() {
      emit('submit');
    }

    function handleDiscard(recommendation: Recommendation) {
      emit('discard', recommendation);
    }
    function handleRemove(recommendation: Recommendation) {
      emit('remove', recommendation);
    }
    function handleSchedule(recommendation: Recommendation) {
      emit('schedule', recommendation);
    }
    function isActiveRecommendation(recommendation: Recommendation) {
      return props.selectedRecommendations.includes(recommendation);
    }

    function isHiddenRecommendation(recommendation: Recommendation) {
      return props.hiddenRecommendations.includes(recommendation);
    }

    return {
      handleDiscard,
      handleRemove,
      handleSchedule,
      handleSubmit,
      Icon,
      isActiveRecommendation,
      recommendationItems,
      Routes
    };
  }
});
