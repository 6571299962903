import { openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/syllabus.png'


const _hoisted_1 = {
  src: _imports_0,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("img", _hoisted_1))
}