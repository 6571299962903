
import { defineComponent, PropType } from 'vue';
import Syllabus from '@/components/SyllabusComponent.vue';
import RecommendationCardSelector from '@/components/recommendation/RecommendationCardSelectorComponent.vue';
import EventGrid from '@/components/event/grid/EventGridComponent.vue';
import { GradedEvent, Student, Recommendation } from '@/models';
import Routes from '@/router/Routes';
import store from '@/store';

export default defineComponent({
  components: {
    EventGrid,
    RecommendationCardSelector,
    Syllabus
  },
  props: {
    student: {
      type: Object as PropType<Student>,
      required: true
    },
    events: {
      type: Array as PropType<Array<GradedEvent>>,
      required: true
    },
    recommendations: {
      type: Array as PropType<Array<Recommendation>>,
      required: true
    }
  },
  methods: {
    async scheduleEvent(recommendation: Recommendation) {
      await store.dispatch(
        'eventModule/addScheduledEvent',
        recommendation.event
      );
    },
    async removeEvent(recommendation: Recommendation) {
      await store.dispatch(
        'eventModule/removeScheduledEvent',
        recommendation.event
      );
    }
  },

  setup() {
    return {
      Routes
    };
  }
});
