
import { defineComponent, PropType } from 'vue';
import ManeuverCard from '@/components/event/ManeuverCardComponent.vue';
import { Recommendation } from '@/models';

export default defineComponent({
  components: { ManeuverCard },
  props: {
    recommendation: {
      type: Object as PropType<Recommendation>
    }
  }
});
