
import { defineComponent, PropType } from 'vue';
import { GradedManeuver } from '@/models';

export default defineComponent({
  props: {
    maneuver: {
      type: Object as PropType<GradedManeuver>
    },
    title: {
      type: String
    }
  }
});
